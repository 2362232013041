
import { defineComponent } from '@vue/runtime-core';
import chooserModule, { load } from '../Chooser/module';
import { clearModule } from '../Patient/module';

export default defineComponent({
    beforeMount(){
        clearModule();
        if(this.$store.hasModule('chooser')) return;
        
        this.$store.registerModule('chooser', chooserModule);
        load();
    },
    unmounted(){
        this.$store.unregisterModule('patient');
    }
});

import { namespacedToCommit } from "@/helpers/store";
import { Patient } from "@/ts/interfaces/Patient";
import { Questionnaire, RawQuestionnaire } from "@/ts/interfaces/Questionnaire";
import { PatientState } from "@/ts/state/PatientState";

const initialState = () => ({
    id: null,
    name: null,
    email: null,
    nickname: null,
    questionnaires: [],
    questionnaireCommits: [],

    // intaken: true,
    // isUser: false,
    // intakeQuestions: {},
    // fetched: false,
});

const processQuestionnaire = (questionnaire: RawQuestionnaire): Questionnaire => {
    const entries = Object.entries(questionnaire).map(entry => {
        const [key, item] = entry;
        if(['start_date', 'end_date', 'fill_next_on', 'created_at'].includes(key))
            return [key, item ? new Date(item) : null];
        return entry;
    });
    return Object.fromEntries(entries);
}

const mutations = {
    setPatient(state: PatientState, patient: Patient){
        Object.assign(state, patient);
    },
    setQuestionnaires(state: PatientState, questionnaires: RawQuestionnaire[]){
        state.questionnaires = questionnaires.map(processQuestionnaire).sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
    },
    setQuestionnaire(state: PatientState, questionnaire: RawQuestionnaire){
        let index = state.questionnaires.findIndex(q => q.id === questionnaire.id);
        if(index < 0)
            index = state.questionnaires.length;

        state.questionnaires[index] = processQuestionnaire(questionnaire);
    },
    addCommit({ questionnaireCommits: commits }: PatientState, commitId: number){
        if(!commits.includes(commitId))
            commits.push(commitId);
    },
    clearModule(state: PatientState){
        Object.assign(state, initialState())
    }

    // assignFetched: (state, assign) => Object.assign(state, assign),
}

const toCommit = namespacedToCommit('patient');

export const setPatient = toCommit(mutations.setPatient);
export const setQuestionnaire = toCommit(mutations.setQuestionnaire);
export const setQuestionnaires = toCommit(mutations.setQuestionnaires);
export const addCommit = toCommit(mutations.addCommit);
export const clearModule = toCommit(mutations.clearModule);

export default {
    namespaced: true,
    state: initialState(),
    mutations
}

<template>
    <div id='chooseQuestions'>
        <!-- <page-template :headerText='title' :headerInfo='headerInfo'> -->
        <div id='chooserDisplay'>
            <slot></slot>
            <h1 :style='{ width }'>
                <div @click='mode = "questions"' :class='{ selected: mode === "questions", solo: noCollections }'>{{ title }}</div>
                <div v-if='!noCollections' @click='mode = "collections"' :class='{ selected: mode === "collections" }'>בחירת שאלונים</div>
            </h1>
            <div :style='{ flex: 1, width }'>
                <questions v-if='mode === "questions"' />
                <question-collections v-if='mode === "collections"' />
            </div>
        </div>

        <sidebar @submit='$emit("submit")' v-bind='{ submitText, allowLegends }' />
        <!-- <slot name='sidebar'></slot> -->
    </div>
</template>

<script>
import { defineComponent } from '@vue/runtime-core';
import Questions from './Questions';
import QuestionCollections from './QuestionCollections';
import Sidebar from './Sidebar';
import chooserModule, { load } from './module';

export default defineComponent({
    props: {
        title: String,
        tooltipIntent: String,
        tooltipAction: String,
        submitText: String,
        noCollections: Boolean,
        allowLegends: Boolean,
        onLoad: Function,
        width: { type: String, default: '60vw' }
    },
    emits: ['submit'],
    async beforeMount(){
        if(!this.$store.hasModule('chooser'))
            this.$store.registerModule('chooser', chooserModule);

        if(!/\/(edit|setintake)/g.test(this.$route.path)){
            await load();
            this.onLoad?.();
        }
    },
    components: { Questions, QuestionCollections, Sidebar },
    data: () => ({ mode: 'questions' }),
    computed: {
        headerInfo(){
            if(!this.tooltipIntent)
                return null;
            
            const click = this.$parse(['לחץ','לחצי']);
            const want = this.$parse(['תרצה','תרצי']);
            const fill = this.$parse(['מלא','מלאי']);

            const parseText = text => text.replace('{click}', click).replace('{want}', want).replace('{fill}', fill);
            return `${click} על השאלות ש${want} ${parseText(this.tooltipIntent)} - הצבע שלהן ישתנה - ולאחר מכן ${parseText(this.tooltipAction)}`;
        }
    }
});
</script>

<style lang="scss" scoped>
#chooserDisplay {
    flex: 1;
    height: calc(100vh - 64px);
    overflow-y: scroll;
    padding-bottom: 4rem;

    & > * {
        margin: 0 auto;
    }

    & h1 {
        margin-top: 2rem !important;
        display: flex;
        padding: 0;
        overflow: hidden;
        border-radius: 0.3rem;
        box-shadow: -4px 4px 12px -8px #aaa;

        & div {
            padding: 16px;
            font: inherit;
            flex: 1;
            &:not(.solo) {
                cursor: pointer;
            }

            &:not(.selected), &.solo {
                background-color: $swatchB;
                &:not(.solo):hover {
                    background-color: $hoverB;
                }
            }
        }
    }
}
</style>

<style lang="scss">
#chooseQuestions {
    display: contents;
    
	.tree {
		position: absolute;
		right: -0.6rem;
		max-width: 1.25rem;
		cursor: default;
	}
}

.chooser {
	padding: 3rem 2rem 2rem;

	&.questions, &.collections {
		margin: 0 auto;
        padding: 3rem 0;
	}
	
	&.collections li > div {
        @include shadow;
		cursor: auto;
		padding: 0;
        background-color: #fff;
        border-radius: 0.3rem;
        min-height: 8rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;

		& > :first-child {
			width: 100%;
			padding: 0.5rem;
		}
		& button {
			border-radius: 0; margin: 0; min-width: 0; box-shadow: none;
			width: 50%;
			padding: 0.5rem 0;
			background-color: transparent;

			&:not([disabled]).active {
				background-color: $hoverC;
				@include shadow;
			}
			&[disabled] {
				opacity: 0.3;
				cursor: auto;
			}

            &:hover {
                background-color: $swatchC;
            }
		}
		& img { max-width: 2rem }
	}
}
</style>
